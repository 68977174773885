<template>
  <v-container
      fluid
      grid-list-xl
      fill-height>
    <v-layout
        justify-center
        align-center
    >

      <template v-if="loaded">
        <data-table ref="datatable"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Email Logs'"
                    :isDetail="true"
                    :isExport="false"
                    :pre-selected-filters="filters.selectedFilters"
        >
          <template slot="filters">
            <filters :filters="filters.typeFilters"></filters>
            <filters :filters="filters.subTypeFilters"></filters>
          </template>
        </data-table>
      </template>

      <!-- Email Detail -->
      <email-detail v-if="emailDetailDialog"
                    :visible="emailDetailDialog"
                    :rowData="selectedEmail"
                    v-on:hide="hideDetailModal"
      >
      </email-detail>

    </v-layout>
  </v-container>
</template>

<script>
import DataTable from "../components/common/dataTable/DataTable";
import Filters from "../components/common/dataTable/filters/Filters";
import EmailDetail from "../components/messaging/EmailDetail";

export default {
  name: "EmailLogs",
  components: {Filters, DataTable, EmailDetail},
  data() {
    return {
      apiurl: 'email-logs',
      search: {
        placeholder: 'Search by booking id, name, username ...',
        searchAble: true
      },
      sortOrder: [
        {
          field: 'updated_at',
          sortField: 'updated_at',
          direction: 'desc'
        }
      ],
      loaded: false,
      filters: null,
      emailDetailDialog: false,
      selectedEmail: null
    }
  },
  events: {
    'open-details': function(data) {
      this.selectedEmail = data;
      this.emailDetailDialog = true;
    },
  },
  created() {
    this.fetchFilters();
  },
  methods: {
    fetchFilters() {
      this.$http.get(this.url + 'filters/email-logs').then((res) => {
        if (res.data) {
          this.filters = res.data;
          this.loaded = true;
        } else {
          alert('there is some problem');
        }
      });
    },
    hideDetailModal() {
      this.selectedEmail = null;
      this.emailDetailDialog = false;
    },
  }
}
</script>
